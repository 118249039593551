import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { ch: '首页' },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: { ch: '产品介绍' },
    component: () => import('../views/product.vue')
  },
  {
    path: '/company',
    name: 'company',
    meta: { ch: '公司介绍' },
    component: () => import('../views/company.vue')
  },
  {
    path: '/connectUs',
    name: 'connectUs',
    meta: { ch: '联系我们' },
    component: () => import('../views/connectUs.vue')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    meta: { p: 'product', ch: '产品详情' },
    component: () => import('../views/productDetail.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
