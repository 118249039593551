<template>
    <div id="app">
        <Header></Header>
        <div id="Content">
            <router-view />
        </div>
        <!-- 元素占位大法 兼容-->
        <div :style="{ height: offsetHeight + 'px' }"></div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: { Header, Footer },
    data() {
        return {
            footHeight: 0
        }
    },
    computed: {
        offsetHeight() {
            return document.body.clientWidth > 992 || document.documentElement.clientWidth > 992 ? (this.$store.state.miniFooter ? 83 : 412) : 0
        },
    },
    mounted() {
        this.$router.afterEach((to, from, next) => { window.scrollTo(0, 0) })
    },
    watch: {
        $route(to, from) {
            this.$store.commit('switchNav', { routeObj: to, document });
        }
    }
}
</script>

<style lang="less">
@import url("./assets/iconfont/iconfont.css");
html,
body {
    height: 100%;
}
html,
body {
    font-size: 62.5%; /* 10÷16=62.5% */
}
@media screen and (max-width: 1200px) {
    html,
    body {
        font-size: 60% !important;
    }
}
@media screen and (max-width: 992px) {
    html,
    body {
        font-size: 50% !important;
    }
}
@media screen and (max-width: 576px) {
    html,
    body {
        font-size: 65% !important;
    }
}
#app {
    font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
    --base-blue: #015aab;
    user-select: none;
    // display: flex;
    // flex-direction: column;
    height: 100%;
}
.selector-for-some-widget {
    box-sizing: content-box;
}
#Header {
    z-index: 100;
}
#Content {
    position: relative;
    z-index: 10;
    // height: calc(100% - 82px - 83px);
}
#Footer {
    z-index: 1;
}

// ::-webkit-scrollbar {
//     width: 0 !important;
// }

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.scale {
    img {
        transition: 0.3s ease;
        &:hover {
            transform: scale(1.1);
        }
    }
}
@media screen and (max-width: 440px) {
    .scale {
        img {
            transition: 0.3s ease;
            &:hover {
                transform: none;
            }
        }
    }
}

@keyframes trans_l {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}
@keyframes trans_r {
    from {
        transform: translateX(50%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}
</style>
